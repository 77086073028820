@font-face {
  font-family: 'Lato', sans-serif;
  src: local(Lato), url(https://fonts.googleapis.com/css?family=Lato) format('woff2');
  font-display: auto;
}

.App {
  background-color: #FFFFFF;
}

.App {
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  text-align: center;
  font-family: 'Lato', Verdana, sans-serif;
  font-weight: lighter;
  transition: background-color ease-in-out 0.5s;
}

* {
margin: 0px; 
padding: 0px; 
box-sizing: border-box;
}

body, html {
height: 100%;
}

a {
  line-height: 1.7;
  color: #666;
  margin: 0;
  transition: all .4s;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s
}

a:hover {
text-decoration: none;
/*color: #57b846;*/
}

h1,h2,h3,h4,h5,h6 {
margin: 0px;
}

li,ul {
  margin: 0;
  list-style-type: none
}

button {
  outline: none!important;
  border: none;
  background: transparent
}

button:hover {
  cursor: pointer
}

.dayNightToggleImage {
    cursor: pointer;
    min-width: 30px;
    width: 2.1vw;
    opacity: 0.125;
    transition: opacity .4s ease-in-out;
}

.dayNightToggleContainer {
    position: fixed;
    bottom: 0;
    left: 0
}

.aboutContainer,
.mainPageGalleryImage {
  background-color: #fff
}

.mainPageGalleryContainer {
  margin-top: 4%;
}

.creditContainerCenter,
.creditContainerRight {
  display: inline-block;
}

.creditContainerCenter {
  margin: 0 auto;
}

.creditContainerCenter p {
  color: rgb(100, 122, 134);
  font-size: 0.75em;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
  transition: all 0.5s;
}

.creditContainerRight {
  float: right;
  width: 50%;
}

.creditContainerRight p {
  font-size: 0.75em;
  margin-right: 1.2em;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
  transition: all 0.5s;
  float: right;
}

.creditContainer p ::before {
  bottom: -50px;
}

.creditContainer p ::after {
  bottom: 0px;
}

.mainPageGalleryContainer {
  min-height: 71vh;
}

.footer {
  height: fit-content;
  width: 100vw;
  overflow: hidden;
}

.footerColLeft {
  height: fit-content;
  overflow: hidden;
  width: 33.33%;
}

.footerColCenterRight {
  width: 66.67%;
  float: right;
}

.creditEmailLink {
  cursor: pointer;
  text-decoration: none;
}
/*
.creditEmailLink:hover {
  color: rgb(100, 122, 134)
}
*/
@media (hover: hover) {
  .dayNightToggleImage:hover {
    opacity: 1;
  }
}

@media only screen and (max-width: 746px) {
  .creditContainerCenter,
  .creditContainerRight {
    display: block;
    float: right;
    width: 100%;
  }

  .creditContainerCenter {
    margin: 0;
  }

  .creditContainerCenter p {
    float: right;
    margin-right: 1.2em;
    margin-bottom: 0;
  }

  .creditContainerRight p {
    margin-top: 0.15em;
  }
}



/* Mobile - iPhoneXs and smaller */

/* Portrait */

@media only screen and (max-height: 896px) and (min-height: 480px) and (max-width: 414px) and (min-width: 320px) {
  .mainPageGallery,
  .aboutContainer {
    margin-top: 2em;
  }

  .aboutContainer {
    padding-top: 2em;
    max-width: 83.15vw;
    margin-top: 2.2em;
  }

  .creditContainerCenter p,
  .creditContainerRight p {
    margin-right: 0.3em;
    font-size: 0.7em;
  }

  .creditContainerRight p {
    margin-top: 0.1em;
  }
}


/* Landscape */

@media only screen and (max-width: 896px) and (min-width: 480px) and (max-height: 414px) and (min-height: 320px) {
  .creditContainerCenter p,
  .creditContainerRight p {
    margin-right: 0.3em;
    font-size: 0.7em;
  }
}